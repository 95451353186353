<template>
  <div>
    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code
          title="Szerkesztés"
      >
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    label="Felhasználó"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="felhasználó"
                      rules="required"
                      vid="user"
                  >
                    <v-select
                        v-model="formData.user"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="userOptions"
                        :filterable="false"
                        :clearable="false"
                        @search="searchUsers"
                        @input="setUserData"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Név"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="név"
                      rules="required"
                      vid="name"
                  >
                    <b-form-input
                        v-model="formData.name"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Email cím"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="email cím"
                      rules="required|email"
                      vid="email"
                  >
                    <b-form-input
                        v-model="formData.email"
                        :readonly="true"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Telefonszám"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="telefonszám"
                      rules="required"
                      vid="phone"
                  >
                    <VuePhoneNumberInput
                        v-model="formData.phone"
                        :error="errors.length > 0"
                        placeholder="telefonszám"
                        default-country-code="HU"
                        color="#42968E"
                        valid-color="#404656"
                        error-color="#EA5455"
                        dark-color="#283046"
                        :no-example="true"
                        :dark="$store.state.appConfig.layout.skin === 'dark'"
                        :translations="{
                      countrySelectorLabel: 'Ország',
                      countrySelectorError: 'Válassz egy országot',
                      phoneNumberLabel: 'Telefonszám'
                    }"
                        @input="checkIfCanCreateReservation"
                        @update="phoneChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Ország"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="ország"
                      rules="required"
                      vid="country"
                  >
                    <v-select
                        v-model="formData.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="countryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Irányítószám"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="irányítószám"
                      rules="required"
                      vid="zip"
                  >
                    <b-form-input
                        v-model="formData.zip"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label="Település"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="település"
                      rules="required"
                      vid="city"
                  >
                    <b-form-input
                        v-model="formData.city"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Cím"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="cím"
                      rules="required"
                      vid="address1"
                  >
                    <b-form-input
                        v-model="formData.address1"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Cím kiegészítés"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="cím kiegészítés"
                      vid="address2"
                  >
                    <b-form-input
                        v-model="formData.address2"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    label="Horgászhely"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="horgászhely"
                      rules="required"
                      vid="fishingSpot"
                  >
                    <v-select
                        v-model="formData.fishingSpot"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="fishingSpotOptions"
                        @input="fishingSpotChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Tervezett érkezés"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="tervezett érkezés"
                      rules="required"
                      vid="startDate"
                  >
                    <flat-pickr
                        v-model="formData.startDate"
                        class="form-control"
                        :config="flatPickRConfig"
                        @input="fishingSpotChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Tervezett távozás"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="tervezett távozás"
                      rules="required"
                      vid="endDate"
                  >
                    <flat-pickr
                        v-model="formData.endDate"
                        class="form-control"
                        :config="flatPickRConfig"
                        @input="fishingSpotChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12">
                <h4>Résztvevők</h4>
              </b-col>

              <b-col sm="12" md="6">Résztvevők összesen</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="résztvevők összesen"
                    rules="required"
                    vid="inTotal">
                  <div>{{ formData.participants.inTotal.count }} fő</div>
                  <input
                      type="hidden"
                      v-model="formData.participants.inTotal.count"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">{{ $formatters.formatPriceText(formData.participants.inTotal.cost) }}
                Ft
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col sm="12" md="6">Felnőtt és ifi horgászok</b-col>
              <b-col sm="12" md="6">
                <validation-provider
                    #default="{ errors }"
                    name="felnőtt és ifi horgászok"
                    rules="required"
                    vid="adultYoungster">
                  <div>
                    {{ formData.participants.adultYoungster.count }} fő
                  </div>
                  <input
                      type="hidden"
                      v-model="formData.participants.adultYoungster.count"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="participants-level-1" sm="12" md="6">Vendéghorgász</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="vendéghorgász"
                    rules="required"
                    vid="guestAll">
                  <v-select
                      v-model="formData.participants.guestAll.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">{{ $formatters.formatPriceText(formData.participants.guestAll.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.participants.guestAll.count.value > 0">
              <b-col class="participants-level-2" sm="12" md="6">ebből ifi</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="ifi"
                    rules="required"
                    vid="guestYoungster">
                  <v-select
                      v-model="formData.participants.guestYoungster.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.guestYoungster.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.participants.guestAll.count.value > 0">
              <b-col class="participants-level-2" sm="12" md="6">ebből 70 év feletti</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="70 év feletti"
                    rules="required"
                    vid="guestOlderThan70Years">
                  <v-select
                      v-model="formData.participants.guestOlderThan70Years.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.guestOlderThan70Years.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.participants.guestAll.count.value > 0">
              <b-col class="participants-level-2" sm="12" md="6">ebből hölgy</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="hölgy"
                    rules="required"
                    vid="guestLady"

                >
                  <v-select
                      v-model="formData.participants.guestLady.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">{{
                  $formatters.formatPriceText(formData.participants.guestLady.cost)
                }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.participants.guestAll.count.value > 0">
              <b-col class="participants-level-2" sm="12" md="6">állami horgászjegyet váltana</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="állami horgászjegyet váltana"
                    rules="required"
                    vid="guestWantsStateTicket">
                  <v-select
                      v-model="formData.participants.guestWantsStateTicket.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.guestWantsStateTicket.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col class="participants-level-1" sm="12" md="6">BSHE alaptag</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="BSHE alaptag"
                    rules="required"
                    vid="BSHEBasicMember">
                  <v-select
                      v-model="formData.participants.BSHEBasicMember.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.BSHEBasicMember.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col class="participants-level-1" sm="12" md="6">BSHE teljes jogú tag</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="BSHE teljes jogú tag"
                    rules="required"
                    vid="BSHEFullMember">
                  <v-select
                      v-model="formData.participants.BSHEFullMember.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.BSHEFullMember.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="childrenEnabled">
              <b-col sm="12" md="6">Gyermek horgászok</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="gyermek horgászok"
                    rules="required"
                    vid="child">
                  <v-select
                      v-model="formData.participants.child.count"
                      dir="ltr"
                      label="title"
                      :options="childAttendantOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.child.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="childrenEnabled && formData.participants.child.count.value > 0">
              <b-col class="participants-level-2" sm="12" md="6">területi engedélyt váltana</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="területi engedélyt váltana"
                    rules="required"
                    vid="childWantsRegionalTicket">
                  <v-select
                      v-model="formData.participants.childWantsRegionalTicket.count"
                      dir="ltr"
                      label="title"
                      :options="adultYoungsterOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.childWantsRegionalTicket.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12" md="6">Kísérők</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="kísérők"
                    rules="required"
                    vid="attendant">
                  <v-select
                      v-model="formData.participants.attendant.count"
                      dir="ltr"
                      label="title"
                      :options="childAttendantOptions"
                      :clearable="false"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.participants.attendant.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12">
                <h4>Opciók</h4>
              </b-col>
              <b-col sm="12" md="6">Opciók összesen</b-col>
              <b-col sm="12" md="4">&nbsp;</b-col>
              <b-col sm="12" md="2">{{ $formatters.formatPriceText(formData.options.inTotal.cost) }}
                Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.fishingSpot.canUseGeneralFishingMethod">
              <b-col sm="12" md="6">Horgászmódszer</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="horgászmódszer"
                    rules="required"
                    vid="fishingMethod">
                  <v-select
                      v-model="formData.options.fishingMethod.value"
                      dir="ltr"
                      label="title"
                      :clearable="false"
                      :options="fishingMethodOptions"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.fishingMethod.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.fishingSpot.canUseBoat">
              <b-col sm="12" md="6">Csónak etetéshez</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="csónak etetéshez"
                    rules="required"
                    vid="boatForFeeding">
                  <v-select
                      v-model="formData.options.boatForFeeding.value"
                      dir="ltr"
                      label="title"
                      :clearable="false"
                      :options="yesNoOptions"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.boatForFeeding.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.fishingSpot.hasPowerConsumption">
              <b-col sm="12" md="6">Áramfelvétel</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="áramfelvétel"
                    rules="required"
                    vid="powerConsumption">
                  <v-select
                      v-model="formData.options.powerConsumption.value"
                      dir="ltr"
                      label="title"
                      :clearable="false"
                      :options="yesNoOptions"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.powerConsumption.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.fishingSpot.hasParkingFee">
              <b-col sm="12" md="6">Gépjármű belépő</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="gépjármű belépő"
                    rules="required"
                    vid="vehicleEntranceFee">
                  <v-select
                      v-model="formData.options.vehicleEntranceFee.value"
                      dir="ltr"
                      label="title"
                      :clearable="false"
                      :options="vehicleOptions"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.vehicleEntranceFee.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="formData.fishingSpot.canUseCaravan">
              <b-col sm="12" md="6">Lakókocsi belépő</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="lakókocsi belépő"
                    rules="required"
                    vid="caravanEntranceFee">
                  <v-select
                      v-model="formData.options.caravanEntranceFee.value"
                      dir="ltr"
                      label="title"
                      :clearable="false"
                      :options="vehicleOptions"
                      @input="calculateCountAndCost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.caravanEntranceFee.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12">
                <h4>Összesítés</h4>
              </b-col>

              <b-col sm="12" md="6">Minimálisan fizetendő díj</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="minimálisan fizetendő díj"
                    rules="required"
                    vid="defaultFee">
                  <div>{{ $formatters.formatPriceText(formData.minimumFee.defaultFee) }} Ft</div>
                  <input
                      type="hidden"
                      v-model="formData.minimumFee.defaultFee"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.minimumFee.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12" md="6">Egyéb díj</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="egyéb díj"
                    vid="other">
                  <b-input-group>
                    <b-form-input
                        type="number"
                        v-model="formData.options.other.cost"
                        @input="calculateCountAndCost"
                    />
                    <b-input-group-append>
                      <b-input-group-text>Ft</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(formData.options.other.cost) }} Ft
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col sm="12" md="6">Egyéb megjegyzés</b-col>
              <b-col sm="12" md="4">
                <validation-provider
                    #default="{ errors }"
                    name="egyéb megjegyzés"
                    vid="adminComment">
                  <b-form-textarea
                      v-model="formData.adminComment"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col sm="12" md="2">
                &nbsp;
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="6">Foglalás összesen</b-col>
              <b-col sm="12" md="4">
                &nbsp;
              </b-col>
              <b-col sm="12" md="2">
                {{ $formatters.formatPriceText(grossTotal) }} Ft
              </b-col>
            </b-row>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-overlay
                  :show="loading"
                  spinner-variant="primary"
                  spinner-type="grow"
                  rounded="sm"
              >
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    :disabled="!canCreateReservation"
                    @click.prevent="submitForm"
                >
                  Foglalás
                </b-button>
              </b-overlay>
              <b-overlay
                  :show="loading"
                  spinner-variant="primary"
                  spinner-type="grow"
                  rounded="sm"
              >
                <b-button v-if="formData.status === 'request' || formData.status === 'conditional'"
                          class="float-right mr-2"
                          variant="primary"
                          :disabled="!canCreateReservation"
                          @click.prevent="confirmReservation"
                >
                  Jóváhagyás
                </b-button>
              </b-overlay>
              <b-overlay
                  :show="loading"
                  spinner-variant="primary"
                  spinner-type="grow"
                  rounded="sm"
              >
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-overlay>
            </b-col>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BFormTextarea,
  BOverlay
} from 'bootstrap-vue'
import {
  required, email
} from '@validations'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'
import tabs from '@/views/pages/_components/_tabs/_reservation/update.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BFormTextarea,
    BOverlay,
    editor,
    vSelect,
    cancelAndGOTOPreviousPage,
    VuePhoneNumberInput,
    flatPickr,
    tabs
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        id: '',
        user: '',
        name: '',
        email: '',
        phone: '',
        phoneInternational: '',
        country: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
        fishingSpot: '',
        startDate: '',
        endDate: '',
        participants: {
          inTotal: {
            count: 0,
            cost: 0,
          },
          adultYoungster: {
            count: 0,
            cost: 0,
          },
          guestAll: {
            count: 0,
            cost: 0,
          },
          guestYoungster: {
            count: 0,
            cost: 0,
          },
          guestOlderThan70Years: {
            count: 0,
            cost: 0,
          },
          guestLady: {
            count: 0,
            cost: 0,
          },
          guestWantsStateTicket: {
            count: 0,
            cost: 0,
          },
          BSHEBasicMember: {
            count: 0,
            cost: 0,
          },
          BSHEFullMember: {
            count: 0,
            cost: 0,
          },
          child: {
            count: 0,
            cost: 0,
          },
          childWantsRegionalTicket: {
            count: 0,
            cost: 0,
          },
          attendant: {
            count: 0,
            cost: 0,
          },
        },
        adminComment: '',
        options: {
          inTotal: {
            cost: 0,
          },
          other: {
            value: {
              value: 0,
              title: 0,
            },
            cost: 0,
          },
          fishingMethod: {
            value: {
              value: 'bojlis',
              title: 'bojlis',
            },
            cost: '',
          },
          boatForFeeding: {
            value: {
              value: 'no',
              title: 'Nem',
            },
            cost: '',
          },
          powerConsumption: {
            value: {
              value: 'no',
              title: 'Nem',
            },
            cost: '',
          },
          vehicleEntranceFee: {
            value: {
              value: '0',
              title: '0 db',
            },
            cost: '',
          },
          caravanEntranceFee: {
            value: {
              value: '0',
              title: '0 db',
            },
            cost: '',
          },
        },
        minimumFee: {
          defaultFee: 0,
          cost: 0,
        },
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      loading: false,
      grossTotal: 0,
      minAdult: 0,
      minAdultYoungster: 0,
      maxAdultYoungster: 0,
      maxChildAttendant: 0,
      childrenEnabled: false,
      adultYoungsterOptions: [],
      childAttendantOptions: [],
      userOptions: [],
      countryOptions: [],
      fishingSpotOptions: [],
      fishingMethodOptions: [
        {
          value: 'bojlis',
          title: 'bojlis',
        },
        {
          value: 'általános',
          title: 'általános',
        }
      ],
      yesNoOptions: [
        {
          value: 'yes',
          title: 'Igen',
        },
        {
          value: 'no',
          title: 'Nem',
        }
      ],
      vehicleOptions: [],
      checkInLimitOptions: [],
      checkOutLimitOptions: [],
      canCreateReservation: false,
      tabsLoading: false,
      required,
      email
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservation', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    async processEntity(entity, onlyTabs = false) {
      this.loading = true;
      this.tabsLoading = true;

      if (!onlyTabs) {
        await this.searchUsers(entity.email)

        this.$store.dispatch('fetchFishingSpotsForSelect').then(fsResponse => {
          this.fishingSpotOptions = fsResponse

          if (this.fishingSpotOptions.length) {
            Object.keys(this.fishingSpotOptions).forEach(fsKey => {
              if (entity.fishing_spot_id === this.fishingSpotOptions[fsKey].value) {
                this.formData.fishingSpot = this.fishingSpotOptions[fsKey]
                this.fishingSpotChanged()
              }
            })
          }
        })

        this.$store.dispatch('fetchNationalitiesForSelect').then(response => {
          this.countryOptions = response
        }).finally(() => {
          if (this.userOptions.length) {
            Object.keys(this.userOptions).forEach(uKey => {
              if (entity.user_id === this.userOptions[uKey].value) {
                this.formData.user = this.userOptions[uKey]
              }
            })
          }
          this.formData.name = entity.name
          this.formData.email = entity.email
          this.formData.phone = entity.phone
          this.formData.zip = entity.zip
          this.formData.city = entity.city
          this.formData.address1 = entity.address_1
          this.formData.address2 = entity.address_2

          if (this.countryOptions.length) {
            Object.keys(this.countryOptions).forEach(countryKey => {
              if (this.countryOptions[countryKey].value === entity.country) {
                this.formData.country = {
                  value: this.countryOptions[countryKey].value,
                  title: this.countryOptions[countryKey].title
                }
              }
            })
          }
        })

        this.formData.id = this.$route.params.id
        this.formData.status = entity.status
        this.formData.adminComment = entity.admin_comment

        this.formData.startDate = entity.start_date + " " + entity.planned_arrival
        this.formData.endDate = entity.end_date + " " + entity.planned_departure

        this.formData.participants.inTotal.count = {
          value: entity.participants_in_total,
          title: entity.participants_in_total + ' fő'
        }
        this.formData.participants.adultYoungster.count = {
          value: entity.participants_adult_youngster,
          title: entity.participants_adult_youngster + ' fő'
        }
        this.formData.participants.guestAll.count = {
          value: entity.participants_guest_all,
          title: entity.participants_guest_all + ' fő'
        }
        this.formData.participants.guestYoungster.count = {
          value: entity.participants_guest_youngster,
          title: entity.participants_guest_youngster + ' fő'
        }
        this.formData.participants.guestOlderThan70Years.count = {
          value: entity.participants_guest_older_than_70_years,
          title: entity.participants_guest_older_than_70_years + ' fő'
        }
        this.formData.participants.guestLady.count = {
          value: entity.participants_guest_lady,
          title: entity.participants_guest_lady + ' fő'
        }
        this.formData.participants.guestWantsStateTicket.count = {
          value: entity.participants_guest_wants_state_ticket,
          title: entity.participants_guest_wants_state_ticket + ' fő'
        }

        this.formData.participants.BSHEBasicMember.count = {
          value: entity.participants_BSHE_basic_member,
          title: entity.participants_BSHE_basic_member + ' fő'
        }

        this.formData.participants.BSHEFullMember.count = {
          value: entity.participants_BSHE_full_member,
          title: entity.participants_BSHE_full_member + ' fő'
        }

        this.formData.participants.child.count = {
          value: entity.participants_child,
          title: entity.participants_child + ' fő'
        }

        this.formData.participants.childWantsRegionalTicket.count = {
          value: entity.participants_child_wants_regional_ticket,
          title: entity.participants_child_wants_regional_ticket + ' fő'
        }

        this.formData.participants.attendant.count = {
          value: entity.participants_attendant,
          title: entity.participants_attendant + ' fő'
        }

        Object.keys(entity.billing_items).forEach(key => {
          if (entity.billing_items[key].key === 'other') {
            this.formData.options["other"] = {
              value: {
                value: entity.billing_items[key].gross_total,
                title: entity.billing_items[key].gross_total,
              },
              cost: parseInt(entity.billing_items[key].gross_total),
            }
          }
        })

        this.$store.dispatch('fetchPricingOptions').then(pricingOptionRes => {

          let sum = 0;

          Object.keys(pricingOptionRes.data.options).forEach(remoteKey => {
            Object.keys(entity.pricing_options).forEach(localKey => {
              if (pricingOptionRes.data.options[remoteKey].id === entity.pricing_options[localKey].pricing_option_id) {
                let arrayKey = pricingOptionRes.data.options[remoteKey].key;

                let title = entity.pricing_options[localKey].value;

                if (entity.pricing_options[localKey].value === "yes") {
                  title = 'Igen'
                }

                if (entity.pricing_options[localKey].value === "no") {
                  title = 'Nem'
                }

                if (arrayKey === 'vehicleEntranceFee' || arrayKey === 'caravanEntranceFee') {
                  title = entity.pricing_options[localKey].value + " db"
                }

                this.formData.options[arrayKey] = {
                  id: pricingOptionRes.data.options[remoteKey].id,
                  value: {
                    value: entity.pricing_options[localKey].value,
                    title: title,
                  },
                  cost: entity.pricing_options[localKey].gross_total
                }

                sum = parseFloat(sum) + parseFloat(entity.pricing_options[localKey].gross_total)
              }
            })
          })

          this.formData.options["inTotal"].cost = sum

        }).finally(() => {
          this.calculateCountAndCost()
          this.loading = false
        })

      }

      this.tabsLoading = false;
    },
    setUserData() {
      let userIndex = this.formData.user.value
      Object.keys(this.userOptions).forEach(key => {
        if (this.userOptions[key].value === userIndex) {
          this.formData.name = this.userOptions[key].name
          this.formData.email = this.userOptions[key].email
          this.formData.phone = this.userOptions[key].phone
          this.formData.zip = this.userOptions[key].zip
          this.formData.city = this.userOptions[key].city
          this.formData.address1 = this.userOptions[key].address1
          this.formData.address2 = this.userOptions[key].address2

          if (this.countryOptions.length) {
            Object.keys(this.countryOptions).forEach(countryKey => {
              if (this.countryOptions[countryKey].value === this.userOptions[key].country) {
                this.formData.country = {
                  value: this.countryOptions[countryKey].value,
                  title: this.countryOptions[countryKey].title
                }
              }
            })
          }
        }
      })

    },
    searchUsers(search = '') {
      const searchParams = {
        page: 1,
        limit: 50,
        query: search,
      }

      this.$store.dispatch('fetchUsersForSelect', searchParams).then(data => {
        this.userOptions = data
      })
    },
    calculateCountAndCost() {
      this.calculateCount()
      this.calculateCost()
    },
    calculateCount(resetErrors = true) {
      if (resetErrors) {
        this.$refs.simpleRules.reset();
      }
      this.$refs.simpleRules.validate();


      this.formData.participants.inTotal.count = this.formData.participants.guestAll.count.value
          + this.formData.participants.BSHEBasicMember.count.value
          + this.formData.participants.BSHEFullMember.count.value
          + this.formData.participants.child.count.value
          + this.formData.participants.attendant.count.value
      this.formData.participants.adultYoungster.count = this.formData.participants.guestAll.count.value
          + this.formData.participants.BSHEBasicMember.count.value
          + this.formData.participants.BSHEFullMember.count.value

      if(this.formData.participants.guestAll.count.value == "0"){
        this.formData.participants.guestYoungster.count = {
          value: 0,
          title: '0 fő'
        }
        this.formData.participants.guestLady.count = {
          value: 0,
          title: '0 fő'
        }
        this.formData.participants.guestOlderThan70Years.count = {
          value: 0,
          title: '0 fő'
        }
        this.formData.participants.guestWantsStateTicket.count = {
          value: 0,
          title: '0 fő'
        }
      }

      let fd = JSON.parse(JSON.stringify(this.formData.participants))
      fd.guestAll.count = this.formData.participants.guestAll.count.value
      fd.adultYoungster.count = this.formData.participants.adultYoungster.count
      fd.guestYoungster.count = this.formData.participants.guestYoungster.count.value
      fd.guestLady.count = this.formData.participants.guestLady.count.value
      fd.guestOlderThan70Years.count = this.formData.participants.guestOlderThan70Years.count.value
      fd.BSHEBasicMember.count = this.formData.participants.BSHEBasicMember.count.value
      fd.BSHEFullMember.count = this.formData.participants.BSHEFullMember.count.value
      fd.child.count = this.formData.participants.child.count.value
      fd.attendant.count = this.formData.participants.attendant.count.value
      fd.guestWantsStateTicket.count = this.formData.participants.guestWantsStateTicket.count.value
      fd.childWantsRegionalTicket.count = this.formData.participants.childWantsRegionalTicket.count.value

      let od = JSON.parse(JSON.stringify(this.formData.options))
      od.vehicleEntranceFee = this.formData.options.vehicleEntranceFee.value.value
      od.caravanEntranceFee = this.formData.options.caravanEntranceFee.value.value

      // backend validation
      this.$store.dispatch('calculateCount', {
        participants: fd,
        options: od,
        minAdultYoungster: this.minAdultYoungster,
        maxAdultYoungster: this.maxAdultYoungster,
        maxChildAttendant: this.maxChildAttendant
      }).then(() => {
        this.canCreateReservation = true
      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
          this.canCreateReservation = false
        }
      })
    },
    calculateCost() {
      if (
          this.formData.fishingSpot !== '' &&
          this.formData.startDate !== '' &&
          this.formData.endDate !== ''
      ) {

        const startDate = this.formData.startDate.split(" ");
        const endDate = this.formData.endDate.split(" ");

        let fd = JSON.parse(JSON.stringify(this.formData))
        fd.plannedArrival = startDate[1]
        fd.plannedDeparture = endDate[1]
        fd.participants.guestAll.count = this.formData.participants.guestAll.count.value
        fd.participants.adultYoungster.count = this.formData.participants.adultYoungster.count.value
        fd.participants.guestYoungster.count = this.formData.participants.guestYoungster.count.value
        fd.participants.guestLady.count = this.formData.participants.guestLady.count.value
        fd.participants.guestOlderThan70Years.count = this.formData.participants.guestOlderThan70Years.count.value
        fd.participants.BSHEBasicMember.count = this.formData.participants.BSHEBasicMember.count.value
        fd.participants.BSHEFullMember.count = this.formData.participants.BSHEFullMember.count.value
        fd.participants.child.count = this.formData.participants.child.count.value
        fd.participants.attendant.count = this.formData.participants.attendant.count.value
        fd.participants.guestWantsStateTicket.count = this.formData.participants.guestWantsStateTicket.count.value
        fd.participants.childWantsRegionalTicket.count = this.formData.participants.childWantsRegionalTicket.count.value

        fd.options.fishingMethod.value = this.formData.options.fishingMethod.value.value
        fd.options.boatForFeeding.value = this.formData.options.boatForFeeding.value.value
        fd.options.powerConsumption.value = this.formData.options.powerConsumption.value.value
        fd.options.vehicleEntranceFee.value = this.formData.options.vehicleEntranceFee.value.value
        fd.options.caravanEntranceFee.value = this.formData.options.caravanEntranceFee.value.value

        this.$store.dispatch('calculateCost', {
          fishingSpotId: this.formData.fishingSpot.value,
          data: fd,
          dateFrom: startDate[0],
          dateTo: endDate[0],
          checkIn: startDate[1],
          checkOut: endDate[1]
        }).then(response => {

          let otherCost = this.formData.options.other.cost;
          if (otherCost === '') {
            otherCost = 0;
          }

          this.formData.participants.inTotal.cost = parseInt(response.data.all)
          this.formData.participants.guestAll.cost = parseInt(response.data.guest.guest)
          this.formData.participants.guestYoungster.cost = response.data.guest.youngster
          this.formData.participants.guestOlderThan70Years.cost = response.data.guest.olderThan70Years
          this.formData.participants.guestLady.cost = response.data.guest.lady
          this.formData.participants.guestWantsStateTicket.cost = response.data.guest.stateTicket
          this.formData.participants.BSHEBasicMember.cost = response.data.BSHEBasicMember
          this.formData.participants.BSHEFullMember.cost = response.data.BSHEFullMember
          this.formData.participants.child.cost = response.data.child
          this.formData.participants.childWantsRegionalTicket.cost = response.data.childWantsRegionalTicket
          this.formData.participants.attendant.cost = response.data.attendant
          this.formData.minimumFee.cost = response.data.minimumFeeDifference
          this.formData.options.inTotal.cost = response.data.options.options
          this.grossTotal = parseInt(response.data.grossTotal) + parseInt(otherCost)

          this.formData.options.fishingMethod.cost = response.data.options.fishingMethod
          this.formData.options.boatForFeeding.cost = response.data.options.boatForFeeding
          this.formData.options.powerConsumption.cost = response.data.options.powerConsumption
          this.formData.options.vehicleEntranceFee.cost = response.data.options.vehicleEntranceFee
          this.formData.options.caravanEntranceFee.cost = response.data.options.caravanEntranceFee
        })
      }
    },
    phoneChanged(e) {
      if (!e.isValid) {
        this.$refs.simpleRules.validate().then(() => {
          const err = {
            phone: ['A telefonszám formátuma érvénytelen.']
          }
          this.$refs.simpleRules.setErrors(err)
        })
      }
      this.formData.phoneInternational = e.formatInternational
    },
    fishingSpotChanged() {
      this.grossTotal = parseInt(this.formData.fishingSpot.minimumFee)
      this.formData.minimumFee.defaultFee = parseInt(this.formData.fishingSpot.minimumFee)
      this.formData.minimumFee.cost = parseInt(this.formData.fishingSpot.minimumFee)
      this.minAdult = this.formData.fishingSpot.minAdult
      this.minAdultYoungster = this.formData.fishingSpot.minAdultYoungster
      this.maxAdultYoungster = this.formData.fishingSpot.maxAdultYoungster
      this.maxChildAttendant = this.formData.fishingSpot.maxChildAttendant
      this.dateIntervalChanged()

      this.adultYoungsterOptions = [];

      for (let i = 0; i <= this.maxAdultYoungster; i++) {
        this.adultYoungsterOptions.push({
          value: i,
          title: i + " fő",
        })
      }

      this.childAttendantOptions = [];

      for (let i = 0; i <= this.maxChildAttendant; i++) {
        this.childAttendantOptions.push({
          value: i,
          title: i + " fő",
        })
      }

      this.vehicleOptions = [];

      for (let i = 0; i <= this.maxAdultYoungster; i++) {
        this.vehicleOptions.push({
          value: i,
          title: i + " db",
        })
      }

      /**
       * Rekord helyen nem lehet gyermek
       */
      if (this.formData.fishingSpot.pricingTypeName !== 'rekord') {
        this.childrenEnabled = true
      }
    },
    dateIntervalChanged() {
      if (
          this.formData.fishingSpot !== '' &&
          this.formData.startDate !== '' &&
          this.formData.endDate !== ''
      ) {
        const startDate = this.formData.startDate.split(" ");
        const endDate = this.formData.endDate.split(" ");

        this.$store.dispatch('canReserve', {
          startDate: startDate[0],
          endDate: endDate[0],
          checkIn: startDate[1],
          checkOut: endDate[1],
          fishingSpotId: this.formData.fishingSpot.value,
          reservationId: this.$route.params.id
        }).then(() => {
          this.calculateCountAndCost()
        }).catch(error => {
          if (error.response.status === 422) {
            this.$refs.simpleRules.setErrors(error.response.data.errors)
            this.canCreateReservation = false
          }
        })
      }
    },
    async checkIfCanCreateReservation() {
      await this.calculateCountAndCost(false)
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.canCreateReservation = true
        } else {
          return this.canCreateReservation = false
        }
      })
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.country = this.formData.country.value
          fd.user = this.formData.user.value
          fd.participants.guestAll.count = this.formData.participants.guestAll.count.value
          fd.participants.adultYoungster.count = this.formData.participants.adultYoungster.count
          fd.participants.guestYoungster.count = this.formData.participants.guestYoungster.count.value
          fd.participants.guestLady.count = this.formData.participants.guestLady.count.value
          fd.participants.guestOlderThan70Years.count = this.formData.participants.guestOlderThan70Years.count.value
          fd.participants.BSHEBasicMember.count = this.formData.participants.BSHEBasicMember.count.value
          fd.participants.BSHEFullMember.count = this.formData.participants.BSHEFullMember.count.value
          fd.participants.child.count = this.formData.participants.child.count.value
          fd.participants.attendant.count = this.formData.participants.attendant.count.value
          fd.participants.guestWantsStateTicket.count = this.formData.participants.guestWantsStateTicket.count.value
          fd.participants.childWantsRegionalTicket.count = this.formData.participants.childWantsRegionalTicket.count.value

          fd.options.fishingMethod.value = this.formData.options.fishingMethod.value.value
          fd.options.boatForFeeding.value = this.formData.options.boatForFeeding.value.value
          fd.options.powerConsumption.value = this.formData.options.powerConsumption.value.value
          fd.options.vehicleEntranceFee.value = this.formData.options.vehicleEntranceFee.value.value
          fd.options.caravanEntranceFee.value = this.formData.options.caravanEntranceFee.value.value
          fd.options.other.value = this.formData.options.other.cost
          fd.options.other.id = null
          fd.grossTotal = this.grossTotal
          fd.fishingSpotId = this.formData.fishingSpot.value

          this.$store.dispatch('updateReservation', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservations'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    confirmReservation() {
      this.$bvModal
          .msgBoxConfirm('Biztosan jóváhagyod a foglalást?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Jóváhagyás',
            cancelTitle: 'Mégsem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.$store.dispatch('setReservationStatus', {id: this.formData.id, status: 'confirmed'}).then(() => {
                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'reservations'})
                    : this.$router.go(-1)
                this.$helpers.showSuccessToast(`Sikeres státuszmódosítás (${this.formData.id})`)
              })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
